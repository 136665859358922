
import {computed, defineComponent, onMounted, onUnmounted, reactive, ref, toRefs} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import InvoiceStateDTO from "@/models/InvoiceStateDTO";
import {numberFormatter} from "@/service/NumberFormatter"
import {paymentMethodsHelper} from "@/service/PaymentMethodsHelper"
import APIClient from "@/service/APIClient";
import {DealStatus} from "@/enum/DealStatus";
import DealDTO from "@/models/DealDTO";
import Spinner from "@/components/Spinner.vue"
import PaymentDetails from "@/components/PaymentDetails.vue"
import moment from "moment/moment";
import {InvoiceStatus} from "@/enum/InvoiceStatus";

const copyIcon = require('@/assets/img/copyIcon.svg');
const walletIcon = require('@/assets/img/walletIcon.svg');
const attentionIcon = require('@/assets/img/attentionIcon.svg');
const successIcon = require('@/assets/img/successIcon.svg');
const apiUrl = window.API_URL;

const api = new APIClient();
const state = reactive({
  invoice: null as InvoiceStateDTO|null,
  availableMethods: null as string[]|null,
  currentTimestamp: Date.now(),
  payMethod: null as string|null,
  currentDeal: null as DealDTO|null,
  updateCurrentTimeInterval: null as number|null,
  interval: null as number|null,
  disputeAttachmentForm: null as FormData|null,
});

export default defineComponent({
  name: 'Invoice',
  components: {
    Spinner,
    PaymentDetails
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const disputeAttachment = ref(["file"])

    const loadInvoiceData = async (invoiceId: string) => {
      state.invoice = await api.getInvoice(invoiceId)
    }

    const openDispute = async (invoiceId: string) => {
      state.invoice = await api.openDispute(invoiceId, state.disputeAttachmentForm!)
    }

    const setDisputeAttachment = async (invoiceId: string) => {
      state.invoice = await api.setDisputeAttachment(invoiceId, state.disputeAttachmentForm!)
    }

    const addAttachment = (e:any) => {
      state.disputeAttachmentForm = new FormData()
      state.disputeAttachmentForm.append('attachment', e.target.files[0], e.target.files[0].name)
      state.disputeAttachmentForm.append('dealId', state.currentDeal!.id)
    }

    const loadAvailableMethods = async (invoiceId: string) => {
      state.availableMethods = await api.getAvailableMethods(invoiceId);
    }

    const findDeal = (id: string): DealDTO | null => {
      if (state.invoice === null) {
        return null
      }

      let deal = state.invoice.deals.find((deal) => deal.id === id);
      return deal ? deal : null
    }

    const updateCurrentTime = () => {
      state.currentTimestamp = Date.now()
    }

    const getFormattedCancelTime = (deal: DealDTO): string => {
      const canceledAt = moment(new Date(deal.canceledAt));

      let seconds = canceledAt.unix() * 1000 - state.currentTimestamp;
      if (seconds < 0) {
        return '00:00';
      }

      return moment.utc(seconds).format('mm:ss');
    }

    const isExpired = (deal: DealDTO): boolean => {
      const canceledAt = moment(new Date(deal.canceledAt));

      return canceledAt.unix() * 1000 - state.currentTimestamp <= 0;
    }

    const findActiveDeal = (method: string): DealDTO | null => {
      if (state.invoice === null) {
        return null
      }

      let active = state.invoice.deals.find((deal) => deal.isActive && deal.paymentMethod === method);
      return active ? active : null
    }

    const selectPayMethod = async (method: string) => {
      if (state.invoice === null || state.invoice.status === InvoiceStatus.PAID) {
        return;
      }

      router.replace({ path: route.path, query: { id: state.invoice.id, method: method } });

      state.payMethod = method;
      let activeDeal = findActiveDeal(method);
      if (activeDeal === null) {
        state.invoice = await api.startDeal(state.invoice.id, method);
      }

      state.currentDeal = findActiveDeal(method);
    }

    const updateCurrentDeal = async () => {
      if (state.invoice === null || state.currentDeal === null) {
        return;
      }

      state.currentDeal = findDeal(state.currentDeal.id);
    }

    const confirmTransfer = async () => {
      if (state.invoice === null) {
        return;
      }

      if (state.currentDeal !== null) {
        state.invoice = await api.confirmTransfer(state.invoice.id, state.currentDeal.id);
      }

      await updateCurrentDeal();
    }

    const cancelDeal = async () => {
      if (state.invoice === null) {
        return;
      }

      if (state.currentDeal !== null) {
        state.invoice = await api.cancelInvoice(state.invoice.id);
      }

      router.replace({path: route.path, query: {id: state.invoice.id}});
      state.currentDeal = null;
      state.payMethod = null;
    }

    const cancelInvoice = async () => {
      if (state.invoice === null) {
        return;
      }

      state.invoice = await api.cancelInvoice(state.invoice.id);
      if (state.invoice.cancelUrl) {
        setTimeout(() => {
          window.location.href == state.invoice?.cancelUrl
        }, 1000);
      }
    }

    onMounted(async () => {
      const invoiceId = route.query.id as string
      if (state.invoice === null) {
        await loadInvoiceData(invoiceId)
      }
      if (state.availableMethods === null) {
        await loadAvailableMethods(invoiceId)
      }

      if (state.payMethod === null && state.currentDeal === null) {
        const payMethod = route.query.method as string | undefined
        state.payMethod = payMethod ? payMethod : null
        if (state.payMethod !== null) {
          await selectPayMethod(state.payMethod)
        }
      }

      updateCurrentTime()
      state.updateCurrentTimeInterval = setInterval(updateCurrentTime, 1000)
      state.interval = setInterval(async () => {
        if (state.invoice?.status === InvoiceStatus.PAID
          || state.invoice?.status === InvoiceStatus.EXPIRED
          || state.invoice?.status === InvoiceStatus.CANCELED
        ) {
          return;
        }

        await loadInvoiceData(invoiceId)
        if (state.payMethod !== null) {
          await updateCurrentDeal();
        }
      }, 5000)
    });

    onUnmounted(() => {
      if (state.updateCurrentTimeInterval) {
        clearInterval(state.updateCurrentTimeInterval)
      }

      if (state.interval) {
        clearInterval(state.interval)
      }
    })

    onUnmounted(() => {})

    const sortedMethods = computed(() => {
      if (state.availableMethods === null) {
        return state.availableMethods
      }

      return state.availableMethods.sort((a, b) => {
        if (a === 'sberbank' && b !== 'sberbank') {
          return -1;
        } else if (a !== 'sberbank' && b === 'sberbank') {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      })
    })

    return {
      ...toRefs(state),
      numberFormatter,
      selectPayMethod,
      paymentMethodsHelper,
      copyIcon,
      walletIcon,
      attentionIcon,
      successIcon,
      apiUrl,
      isExpired,
      getFormattedCancelTime,
      DealStatus,
      InvoiceStatus,
      cancelDeal,
      confirmTransfer,
      cancelInvoice,
      addAttachment,
      disputeAttachment,
      openDispute,
      setDisputeAttachment,
      sortedMethods
    }
  }
})
